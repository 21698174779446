import { LoaderProps } from './Loader.props'
import CircularProgress from '@mui/material/CircularProgress'

export const Loader = ({ className, ...props }: LoaderProps): JSX.Element => {

	return (
		<div className="z-30 absolute left-0 top-[200px] flex flex-col items-center justify-center flex-nowrap w-full min-w-[350px] min-h-[350px]">
			<CircularProgress
					size={100}
					sx={{ color: '#e0e0e0', position: 'fixed'}}
				/>
		</div>
	)
}