import { DeleteAccountProps } from './DeleteAccount.props'
import { Footer } from '../Footer/Footer'
import styled from 'styled-components'

export const DeleteAccount = ({ className, ...props }: DeleteAccountProps): JSX.Element => {
	return (
		<div className="absolute left-0 top-0 flex flex-col items-center justify-center flex-nowrap w-full bg-[#1A1A1A] min-w-[350px] min-h-full">
			<Text className='text-[1rem] w-[300px]'>
				To delete your account, email
			</Text>
			<Text className='text-[1rem] w-[300px]'>
				WantTo@BeOdyssey.com
			</Text>
			<Text className='text-[1rem] pt-[20px] w-[300px] items-center justify-center text-justify'>
			Your account and all account-related data will be deleted from the app within 7 days.
			</Text>
			<Footer />
		</div>
	)		
}

const Text = styled.div`
	font-family: "Fredoka";
	color: #FFFFFF;
`;