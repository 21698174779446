import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

export interface ILoginState {
	enter: string
	logged: boolean
	id: string
}

const initialState: ILoginState = {
	enter: '',
	logged: false,
	id: ''
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
	toggleLoginTo: (state, action: PayloadAction<ILoginState>) => {
		state.enter = action.payload.enter
		state.logged = action.payload.logged
		state.id = action.payload.id
    }
  }
})

export const { toggleLoginTo } = loginSlice.actions

export const loginState = (state: RootState) => state.login

export default loginSlice.reducer