import { PageProps } from './Page.props'
import { Login } from '../Login/Login'
import { Secret } from '../Secret/Secret'
import { Loader } from '../Loader/Loader'
import { Game } from '../Game/Game'
import { Footer } from '../Footer/Footer'
import { useAppSelector } from '../../store/hooks'
import { Header } from '../Header/Header'

export const Page = ({ className, ...props }: PageProps): JSX.Element => {
	const login = useAppSelector(state => state.login)
	const secret = useAppSelector(state => state.secret.login)
	const loader = useAppSelector(state => state.loader.loader)

	return (
		<div className="absolute left-0 top-0 flex flex-col items-center justify-start flex-nowrap w-full bg-[#1A1A1A] min-w-[350px] min-h-full">
			<Header />
			{
				!login.logged && <Login />
			}
			{
				loader && <Loader />
			}
			{
				login.logged && !secret && <Secret />
			}
			{
				
				(login.logged && secret) && <Game />
			}
			<Footer />
		</div>
	)		
}