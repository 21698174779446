import {configureStore} from '@reduxjs/toolkit'
import loginReducer from './loginSlice'
import secretReducer from './secretSlice'
import loaderReducer from './loaderSlice'

const store = configureStore({
    reducer: {
        login: loginReducer,
        loader: loaderReducer,
        secret: secretReducer,
    },
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

