import axios from 'axios'

export const sendDayRes = async (
	id: string,
	day: string,
	score: string,
	token: string
	) => {
	axios.defaults.headers.post['Content-Type'] = 'application/json'
	axios.defaults.headers.post['Authorization'] = 'Bearer ' + token

	try {
		return await axios.post(process.env.REACT_APP_EXCHANGE_SERVER + 'day-res',
		{
			id: id,
			day: day,
			score: score
		})
	} catch (e) {
		console.log('sendDayRes error:', e)
		return
	}
}

export const sendWeekRes = async (
	id: string,
	day: string,
	score: string,
	token: string
	) => {
	axios.defaults.headers.post['Content-Type'] = 'application/json'
	axios.defaults.headers.post['Authorization'] = 'Bearer ' + token

	try {
		return await axios.post(process.env.REACT_APP_EXCHANGE_SERVER + 'week-res',
		{
			id: id,
			day: day,
			score: score
		})
	} catch (e) {
		console.log('sendWeekRes error:', e)
		return
	}
}

export const sendMonthRes = async (
	id: string,
	month: string,
	score: string,
	token: string
	) => {
	axios.defaults.headers.post['Content-Type'] = 'application/json'
	axios.defaults.headers.post['Authorization'] = 'Bearer ' + token

	try {
		return await axios.post(process.env.REACT_APP_EXCHANGE_SERVER + 'month-res',
		{
			id: id,
			month: month,
			score: score
		})
	} catch (e) {
		console.log('sendMonthRes error:', e)
		return
	}
}

export const sendLogin = async (
	login: string,
	secretCode: string,
	recaptchaToken: string
	) => {
	try {
		const res = await axios.post(process.env.REACT_APP_EXCHANGE_SERVER + 'login',
		{
			login: login,
			secret: secretCode,
			recaptchaToken: recaptchaToken
		})
		return res?.data
	} catch (e) {
		console.log('sendLogin error:', e)
		return
	}
}

export const sendSignup = async (
	email: string,
	secretCode: string,
	login: string,
	recaptchaToken: string
	) => {
	try {
		const res = await axios.post(process.env.REACT_APP_EXCHANGE_SERVER + 'signup',
		{
			email: email,
			secret: secretCode,
			login: login,
			recaptchaToken: recaptchaToken
		})
		return res?.data?.token
	} catch (e) {
		console.log('sendSignup error:', e)
		return
	}
}
