import { LoginProps } from './Login.props'
import { useAppDispatch } from '../../store/hooks'
import { toggleLoginTo, ILoginState } from '../../store/loginSlice'
import { toggleLoaderTo } from '../../store/loaderSlice'
import LoginIcon from '@mui/icons-material/Login'
import { ISecretState, toggleSecretTo } from '../../store/secretSlice'
import VpnKeyIcon from '@mui/icons-material/VpnKey'
import styled from 'styled-components'

export const Login = ({ className, ...props }: LoginProps): JSX.Element => {
	const dispatch = useAppDispatch()

	const signupUser = async () => {
		dispatch(toggleLoaderTo({loader: true}))

		const secret: ISecretState = {
			login: false,
			secret: true,
			change: false,
			token: ''
		}
		dispatch(toggleSecretTo(secret))
		

		const loginState: ILoginState = {
			logged: true,
			id: '',
			enter: 'signup'
		}

		dispatch(toggleLoginTo(loginState))
		dispatch(toggleLoaderTo({loader: false}))	
	}

	const loginUser = async () => {
		dispatch(toggleLoaderTo({loader: true}))
		
		const secret: ISecretState = {
			login: false,
			secret: true,
			change: false,
			token: ''
		}
		dispatch(toggleSecretTo(secret))
		
		const loginState: ILoginState = {
			logged: true,
			id: '',
			enter: 'login'
		}
		dispatch(toggleLoginTo(loginState))
		
		dispatch(toggleLoaderTo({loader: false}))	
	}

	return (
		<div className="absolute left-0 top-0 flex flex-col items-center justify-center flex-nowrap w-full h-full min-w-[350px] pl-3 pr-3">
			<div className="portrait:hidden flex items-center justify-center h-full">
				<div className="flex flex-col items-start justify-center">
					<Text className='text-[2.5rem]'>
						Meet
					</Text>
					<Text className='text-[4rem]'>
						ODYSSEY MEMS
					</Text>
					<Text className='font-light text-[2rem] pt-5'>
						Play to earn game
					</Text>
					<Text className='font-light text-[1.2rem] pt-2'>
						Follow the deals, collect memes,
					</Text>
					<Text className='font-light text-[1.2rem]'>
						look for insights and make money!
					</Text>
					<div className="flex items-center justify-center pt-6">
						<LogInButton className="flex items-center justify-center rounded-md hover:cursor-pointer hover:font-bold pl-[16px] pr-[16px] pt-[12px] pb-[12px] w-[200px]" onClick={loginUser}>
							<LoginIcon sx={{ fill: 'white', }}/>
							<Text className='ml-4 text-[1.5rem]'>
								Log in
							</Text>
						</LogInButton>
						<SignUpButton className="ml-[15px] flex items-center justify-center rounded-md hover:cursor-pointer p-[4px]" onClick={signupUser}>
							<div className="bg-[#1a1a1a] flex items-center justify-center rounded-md hover:bg-transparent pl-[12px] pr-[12px] pt-[8px] pb-[8px] w-[200px]">
								<VpnKeyIcon sx={{ fill: 'white', }}/>
								<Text className='ml-4 text-[1.5rem]'>
									Sign up
								</Text>
							</div>
						</SignUpButton>
					</div>
				</div>
				<div className="flex items-center justify-center ml-[100px] h-full">
					<AnimatedImg
						className="aspect-square rounded-full w-[500px] h-[500px]"
						src="login.webp"
						alt="Login"
					/>
				</div>
			</div>
			<div className="landscape:hidden flex flex-col items-center justify-center">	
				<div className="flex flex-col items-start justify-center pb-4">
					<Text className='md:text-[2rem] sm:text-[1.8rem]'>
						Meet
					</Text>
					<Text className='md:text-[2.5rem] sm:text-[2.3rem]'>
						ODYSSEY MEMS
					</Text>
					<Text className='font-light md:text-[1.4rem] sm:text-[1.2rem] pt-2'>
						Play to earn game
					</Text>
					<Text className='font-light text-[1rem] pt-2'>
						Follow the deals, collect memes,
					</Text>
					<Text className='font-light text-[1rem]'>
						look for insights and make money!
					</Text>
				</div>
				<AnimatedImg
					className="aspect-square rounded-full w-[150px] h-[150px] md:w-[250px] md:h-[250px] mt-2 mb-2 sm:w-[150px] sm:h-[150px] lg:w-[250px] lg:h-[250px] xl:w-[250px] xl:h-[250px] 2xl:w-[250px] 2xl:h-[250px]"
					src="login.webp"
					alt="Login"
				/>
				<div className="flex items-center justify-center pt-4">
					<LogInButton className="flex items-center justify-center rounded-md hover:cursor-pointer hover:font-bold pl-[8px] pr-[8px] pt-[12px] pb-[12px] w-[100px]" onClick={loginUser}>
						<LoginIcon sx={{ fill: 'white', }}/>
						<Text className='ml-2 text-[0.8rem]'>
							Log in
						</Text>
					</LogInButton>
					<SignUpButton className="ml-[20px] flex items-center justify-center rounded-md hover:cursor-pointer p-[4px]" onClick={signupUser}>
						<div className="bg-[#1a1a1a] flex items-center justify-center rounded-md hover:bg-transparent pl-[8px] pr-[8px] pt-[8px] pb-[8px] w-[100px]">
							<VpnKeyIcon sx={{ fill: 'white', }}/>
							<Text className='ml-2 text-[0.8rem]'>
								Sign up
							</Text>
						</div>
					</SignUpButton>
				</div>
			</div>
		</div>
	)
}

const Text = styled.div`
	font-family: "Fredoka";
	color: #FFFFFF;
`;

const LogInButton = styled.div`
	background: linear-gradient(270deg, rgb(151, 71, 255) 0%, rgb(76, 21, 146) 100%);
`;

const SignUpButton = styled.div`
	background: linear-gradient(90deg, rgb(151, 71, 255) 0%, rgb(76, 21, 146) 50%, rgb(219, 0, 255) 100%);
`;

const AnimatedImg = styled.img`
	// animation: 1.5s linear 0s normal none 1 running rot;
	// -webkit-animation: 1.5s linear 0s normal none 1 running rot;

	// @keyframes rot {
	// 	0% {
	// 	  transform: rotate(0deg);
	// 	}
	// 	100% {
	// 	  transform: rotate(360deg);
	// 	}
	//   }
	//   @-webkit-keyframes rot {
	// 	0% {
	// 	  transform: rotate(0deg);
	// 	}
	// 	100% {
	// 	  transform: rotate(360deg);
	// 	}
	//   }
`;