import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from './store'

export interface ISecretState {
  login: boolean
  secret: boolean
  change: boolean
  token: string
}

const initialState: ISecretState = {
  login: false,
  secret: false,
  change: false,
  token: ''
}

export const secretSlice = createSlice({
  name: 'secret',
  initialState,
  reducers: {
	toggleSecretTo: (state, action: PayloadAction<ISecretState>) => {
	  state.login = action.payload.login
    state.secret = action.payload.secret
	  state.change = action.payload.change
	  state.token = action.payload.token
    }
  }
})

export const { toggleSecretTo } = secretSlice.actions

export const secretState = (state: RootState) => state

export default secretSlice.reducer