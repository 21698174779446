import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/public-sans'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Page } from './components/Page/Page'
import { DeleteAccount } from './components/DeleteAccount/DeleteAccount'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function App() {

	return (
	<>
		<ToastContainer />		
		<Routes>
			<Route path="/delete-account" element={ <DeleteAccount /> } />		
			<Route path="/" element={ <Page /> } />
			<Route path="*" element={ <Navigate to={"/"} /> } />
		</Routes>
	</>
	)
}