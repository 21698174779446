import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

export const Game: React.FC = () => {
  const gameUrl = './Game/index.html'
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const adjustIframeHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      if (iframeRef.current) {
        iframeRef.current.style.height = `${vh * 100}px`
      }
    }

    window.addEventListener('resize', adjustIframeHeight)
    adjustIframeHeight()

    return () => {
      window.removeEventListener('resize', adjustIframeHeight)
    }
  }, [])

  return (
    <StyledIframe
      ref={iframeRef}
      title="Odyssey Mems Game"
      src={gameUrl}
      allow="autoplay; fullscreen"
    ></StyledIframe>
  )
}

const StyledIframe = styled.iframe`
  width: 100vw;
  height: 100vh;
  border: none;
`;