import { FooterProps } from './Footer.props'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import styled from 'styled-components'
import { useAppSelector } from '../../store/hooks'

export const Footer = ({ className, ...props }: FooterProps): JSX.Element => {
	const login = useAppSelector(state => state.login)
	const secret = useAppSelector(state => state.secret.login)

	return (
		<div className='absolute bottom-0 h-[35px] flex items-center justify-between w-full z-40'>
			<div className="flex items-center justify-start pl-[10px]">
				{
					(login.logged && secret) &&
					<a
						href="mailto:WantTo@BeOdyssey.com"
						title="For questions, contact WantTo@BeOdyssey.com"
						className='hover:cursor-pointer'
						target='_blank'
						rel='noreferrer'
					>
						<HelpOutlineIcon
							sx={{
								color: '#FFFFFF'
							}}
						/>
					</a>
				}
				{
					!(login.logged && secret) &&
					<HelpOutlineIcon className="opacity-0" />
				}
			</div>
			<div className="flex items-center justify-center">
				<a href="https://t.me/odymavrodi" target='_blank' rel='noreferrer' className='text-[#FFFFFF]'>
					<Text className='portrait:hidden text-[1rem] hover:cursor-pointer ml-[30px]'>
						© Odyssey 2024
					</Text>
					<Text className='landscape:hidden text-[0.8rem] hover:cursor-pointer ml-[30px]'>
						© Odyssey 2024
					</Text>
				</a>
			</div>
			<div className="flex items-center justify-end opacity-0">
				<HelpOutlineIcon />
			</div>
		</div>
	)				
}

const Text = styled.div`
	font-family: "Fredoka";
	font-weight: regular;
	color: #FFFFFF;
`;
