import { SecretProps } from './Secret.props'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { ISecretState, toggleSecretTo } from '../../store/secretSlice'
import IconButton from '@mui/material/IconButton'
import SaveIcon from '@mui/icons-material/Save'
import LoginIcon from '@mui/icons-material/Login'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useState } from 'react'
// import { useEffect, useState } from 'react'
import { strData } from '../../helpers/helpers'
import { toast } from 'react-toastify'
import { sendLogin, sendSignup } from '../../api/api'
import { ILoginState, toggleLoginTo } from '../../store/loginSlice'
import styled from 'styled-components'
import Input from '@mui/material/Input'
// import { reCaptchaExecute } from 'recaptcha-v3-react-function-async'
// import { recaptchaV3 } from '../../constants/captcha'

export const Secret = ({ className, ...props }: SecretProps): JSX.Element => {
	const [login, setLogin] = useState<string>('')
	const [loginError, setLoginError] = useState<boolean>(false)
	const [secretCode, setSecretCode] = useState<string>('')
	const [secretCode2, setSecretCode2] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [secretCodeError, setSecretCodeError] = useState<boolean>(false)
	const [secretCode2Error, setSecretCode2Error] = useState<boolean>(false)
	const [emailError, setEmailError] = useState<boolean>(false)
	const [showSecretCode, setShowSecretCode] = useState<boolean>(false)
	const [showSecretCode2, setShowSecretCode2] = useState<boolean>(false)
	const [processing, setProcessing] = useState<boolean>(false)
	const enter = useAppSelector(state => state.login.enter)
	const dispatch = useAppDispatch()

	// useEffect(() => {
		// const script = document.createElement('script');
		// script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaV3}`;
		// script.async = true;
		// document.body.appendChild(script);
	
		// script.onload = () => {
		//   console.log('ReCaptcha loaded');
		// };
	
		// return () => {
		  // Удаление скрипта reCAPTCHA
		//   document.body.removeChild(script);
	
		  // Удаление виджета reCAPTCHA
		//   const badge = document.querySelector('.grecaptcha-badge');
		//   if (badge) {
		// 	badge.parentNode?.removeChild(badge);
		//   }
	
		  // Очистка ресурсов reCAPTCHA
		//   if (window.grecaptcha && window.grecaptcha.reset) {
		// 	try {
		// 		window.grecaptcha.reset(); // сброс состояния reCAPTCHA
		// 	} catch (e) {
		// 		console.error('Error resetting reCAPTCHA:', e);
		// 	}
		// 	delete window.grecaptcha; // удаление объекта grecaptcha из глобального пространства
		//   }
	// 	};
	// }, []);

	const changeSecretCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSecretCode(strData(event.target.value).trim())
	}

	const changeSecretCode2 = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSecretCode2(strData(event.target.value).trim())
	}

	const changeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(strData(event.target.value).trim())
	}

	const changeLogin = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLogin(strData(event.target.value).trim())
	}

	const handleClickShowSecretCode = () => setShowSecretCode((show) => !show)

	const handleClickShowSecretCode2 = () => setShowSecretCode2((show) => !show)

	const submitHandler = async (event: React.FormEvent) => {
		event.preventDefault()

		if (
			(secretCode.trim().length === 0) ||
			(secretCode !== secretCode2) ||
			(login.trim().length === 0) ||
			((email.trim().length === 0) && (enter === 'signup'))
		) {
			if (secretCode.trim().length === 0) {
				setSecretCodeError(true)
				setTimeout(() => {
					setSecretCodeError(false)
				}, 1000)
			}
			if (secretCode !== secretCode2) {
				setSecretCode2Error(true)
				setTimeout(() => {
					setSecretCode2Error(false)
				}, 1000)
			}
			if (login.trim().length === 0) {
				setLoginError(true)
				setTimeout(() => {
					setLoginError(false)
				}, 1000)
			}
			if ((email.trim().length === 0) && (enter === 'signup')) {
				setEmailError(true)
				setTimeout(() => {
					setEmailError(false)
				}, 1000)
			}
			return
		}

		if (!("WebSocket" in window)) {
			toast.dark(`The browser does not support WebSocket!`, {
			className: 'toast-error',
			position: toast.POSITION.TOP_RIGHT,
			})
			console.log("The browser does not support WebSocket!")
			return	
		}
		
		setProcessing(true)
		let token = ''
		try {
			// const gtoken = await reCaptchaExecute(recaptchaV3, 'auth')

			// const res = await sendSignup(email, secretCode, login, gtoken)
			const res = await sendSignup(email, secretCode, login, '')

			if (res) {
				token = res

				//Сохраняем login и token в localStorage
				localStorage.setItem(
					"BacketGameObj",
					JSON.stringify({
						id: login,
						token: token,
						day: 0,
						week: 0,
						month: 0
					})
				)
			} else {
				toast.dark(`Error in recording registration data.`, {
				className: 'toast-error',
				position: toast.POSITION.TOP_RIGHT,
				})

				setSecretCode('')
				setSecretCode2('')
				setEmail('')
				setLogin('')
				setSecretCodeError(false)
				setSecretCode2Error(false)
				setEmailError(false)
				setLoginError(false)
				setShowSecretCode(false)
				setShowSecretCode2(false)
				setProcessing(false)				
				return
			}
		} catch (e) {
			toast.dark(`Error in recording registration data.`, {
			className: 'toast-error',
			position: toast.POSITION.TOP_RIGHT,
			})
			console.log(e)

			setSecretCode('')
			setSecretCode2('')
			setEmail('')
			setLogin('')
			setSecretCodeError(false)
			setSecretCode2Error(false)
			setEmailError(false)
			setLoginError(false)
			setShowSecretCode(false)
			setShowSecretCode2(false)
			setProcessing(false)			
			return
		}

		const secret: ISecretState = {
			login: true,
			secret: true,
			change: false,
			token: token
		}

		const loginObj: ILoginState = {
			logged: true,
			id: login,
			enter: 'signup'
		}

		setSecretCode('')
		setSecretCode2('')
		setEmail('')
		setLogin('')
		setSecretCodeError(false)
		setSecretCode2Error(false)
		setEmailError(false)
		setLoginError(false)
		setShowSecretCode(false)
		setShowSecretCode2(false)
		dispatch(toggleLoginTo(loginObj))
		dispatch(toggleSecretTo(secret))
		setProcessing(false)
	}

	const loginHandler = async (event: React.FormEvent) => {
		event.preventDefault()

		if ((secretCode.trim().length === 0) || (login.trim().length === 0)) {
			if (secretCode.trim().length === 0) {
				setSecretCodeError(true)
				setTimeout(() => {
					setSecretCodeError(false)
				}, 1000)
			}
			if (login.trim().length === 0) {
				setLoginError(true)
				setTimeout(() => {
					setLoginError(false)
				}, 1000)
			}
			return
		}

		if (!("WebSocket" in window)) {
			toast.dark(`The browser does not support WebSocket!`, {
			className: 'toast-error',
			position: toast.POSITION.TOP_RIGHT,
			})
			console.log("The browser does not support WebSocket!")
			return	
		}
		
		setProcessing(true)
		try {
			// const gtoken = await reCaptchaExecute(recaptchaV3, 'auth')
			
			// const res = await sendLogin(login, secretCode, gtoken)
			const res = await sendLogin(login, secretCode, '')
			if (res) {
				const token = res.token

				//Сохраняем login и token в localStorage
				localStorage.setItem(
					"BacketGameObj",
					JSON.stringify({
						id: login,
						token: token,
						day: 0,
						week: 0,
						month: 0
					})
				)
								
				const secret: ISecretState = {
					login: true,
					secret: true,
					change: false,
					token: token
				}

				const loginObj: ILoginState = {
					logged: true,
					id: login,
					enter: 'login'
				}

				setSecretCode('')
				setLogin('')
				setSecretCodeError(false)
				setLoginError(false)
				setShowSecretCode(false)
				dispatch(toggleLoginTo(loginObj))
				dispatch(toggleSecretTo(secret))
				setProcessing(false)

				return
			}
		} catch (e) {
			toast.dark(`Login error. Try again.`, {
			className: 'toast-error',
			position: toast.POSITION.TOP_RIGHT,
			})
			console.log(e)

			setSecretCode('')
			setLogin('')
			setSecretCodeError(false)
			setLoginError(false)
			setShowSecretCode(false)
			setProcessing(false)

			return
		}
		toast.dark(`Login error. Try again.`, {
		className: 'toast-error',
		position: toast.POSITION.TOP_RIGHT,
		})

		setSecretCode('')
		setLogin('')
		setSecretCodeError(false)
		setLoginError(false)
		setShowSecretCode(false)
		setProcessing(false)

		return
	}

	const handleKeyDown = (event: any) => {
		if (event.key === 'Enter') {
			if (enter !== 'signup') {
				loginHandler(event)
			} else {
				submitHandler(event)
			}
    	}
	}

	const signupUser = async () => {
		const secret: ISecretState = {
			login: false,
			secret: true,
			change: false,
			token: ''
		}
		dispatch(toggleSecretTo(secret))
		
		const loginState: ILoginState = {
			logged: true,
			id: '',
			enter: 'signup'
		}
		dispatch(toggleLoginTo(loginState))	
	}

	const loginUser = async () => {		
		const secret: ISecretState = {
			login: false,
			secret: true,
			change: false,
			token: ''
		}
		dispatch(toggleSecretTo(secret))
		
		const loginState: ILoginState = {
			logged: true,
			id: '',
			enter: 'login'
		}
		dispatch(toggleLoginTo(loginState))
	}

	return (
		<div className="absolute left-0 top-0 flex flex-col items-center justify-center flex-nowrap w-full h-full min-w-[350px] min-h-[520px]">
			<div className="portrait:hidden flex items-center justify-center p-2 h-full min-w-[950px]">
				<div className="flex items-center justify-center flex-col">
					<div className="flex flex-col w-full justify-center items-center sm:w-[400px]">
						<FormControl
							sx={[
								{ m: 1, width: '100%' },
								{	
									'& label.Mui-focused': {
										color: '#FFFFFF',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: '#FFFFFF'
									}
								}
							]}
							variant="standard"
						>
						<InputLabel
							htmlFor="login-landscape"
							sx={[
								{ fontFamily: 'Fredoka', color: '#FFFFFF' }
							]}
						>
							Login
						</InputLabel>
						<Input
							id="login-landscape"
							required
							value={login}
							onChange={changeLogin}
							onKeyDown={handleKeyDown}
							// disabled={processing}
							sx={[
								{ 
									fontFamily: 'Fredoka',
									color: '#FFFFFF',
									borderBottom: loginError? '1px solid red' : '1px solid #FFFFFF'
								},
								{
									'&:hover': {
										borderBottom: loginError? '1px solid red' : '1px solid #FFFFFF'
									}
								}
							]}
						/>
						</FormControl>
						{
							(enter === 'signup') &&
							<FormControl
								sx={[
									{ m: 1, width: '100%' },
									{	
										'& label.Mui-focused': {
											color: '#FFFFFF',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: '#FFFFFF'
										}
									}
								]}
								variant="standard"
							>
							<InputLabel
								htmlFor="email"
								sx={[
									{ fontFamily: 'Fredoka', color: '#FFFFFF' }
								]}
							>
								Email
							</InputLabel>
							<Input
								id="email"
								required
								value={email}
								onChange={changeEmail}
								onKeyDown={handleKeyDown}
								// disabled={processing}
								sx={[
									{ 
										fontFamily: 'Fredoka',
										color: '#FFFFFF',
										borderBottom: emailError? '1px solid red' : '1px solid #FFFFFF'
									},
									{
										'&:hover': {
											borderBottom: emailError? '1px solid red' : '1px solid #FFFFFF'
										}
									}
								]}
							/>
							</FormControl>
						}
						<FormControl
							sx={[
								{ m: 1, width: '100%' },
								{	
									'& label.Mui-focused': {
										color: '#FFFFFF',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: '#FFFFFF'
									}
								}
							]}
							variant="standard">
						<InputLabel
							htmlFor="secret-code-landscape"
							sx={[
								{ fontFamily: 'Fredoka', color: '#FFFFFF' }
							]}
						>
							Password
						</InputLabel>
						<Input
							id="secret-code-landscape"
							type={showSecretCode ? 'text' : 'password'}
							required
							value={secretCode}
							onChange={changeSecretCode}
							onKeyDown={handleKeyDown}
							// disabled={processing}
							sx={[
								{ 
									fontFamily: 'Fredoka',
									color: '#FFFFFF',
									borderBottom: secretCodeError? '1px solid red' : '1px solid #FFFFFF'
								},
								{
									'&:hover': {
										borderBottom: secretCodeError? '1px solid red' : '1px solid #FFFFFF'
									}
								}
							]}
							endAdornment={
							<InputAdornment position="end">
								<IconButton
								aria-label="toggle secret code visibility"
								onClick={handleClickShowSecretCode}
								sx={[
									{ color: '#FFFFFF' },
									{
										'&:hover': {
											color: '#9747ff'
										}
									}
								]}
								edge="end"
								>
								{showSecretCode ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
							}
						/>
						</FormControl>
						{
							(enter === 'signup') &&
							<FormControl
								sx={[
									{ m: 1, width: '100%' },
									{	
										'& label.Mui-focused': {
											color: '#FFFFFF',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: '#FFFFFF'
										}
									}
								]}
								variant="standard">
							<InputLabel
								htmlFor="secret-code2"
								sx={[
									{ fontFamily: 'Fredoka', color: '#FFFFFF' }
								]}
							>
								Repeat password
							</InputLabel>
							<Input
								id="secret-code2"
								type={showSecretCode2 ? 'text' : 'password'}
								required
								value={secretCode2}
								onChange={changeSecretCode2}
								onKeyDown={handleKeyDown}
								// disabled={processing}
								sx={[
									{ 
										fontFamily: 'Fredoka',
										color: '#FFFFFF',
										borderBottom: secretCode2Error? '1px solid red' : '1px solid #FFFFFF'
									},
									{
										'&:hover': {
											borderBottom: secretCode2Error? '1px solid red' : '1px solid #FFFFFF'
										}
									}
								]}
								endAdornment={
								<InputAdornment position="end">
									<IconButton
									aria-label="toggle secret code visibility"
									onClick={handleClickShowSecretCode2}
									sx={[
										{ color: '#FFFFFF' },
										{
											'&:hover': {
												color: '#9747ff'
											}
										}
									]}
									edge="end"
									>
									{showSecretCode2 ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
								}
							/>
							</FormControl>
						}
					</div>
					<div className="flex w-full justify-end items-center">
						{
							(enter === 'signup') &&
							<div className="flex pt-4">
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="save"
									// disabled={processing}
									sx={[
										{
											color: '#e0e0e0',
											padding: '0px'
										},
										{
											'&:hover': {
												color: '#9747ff'
											}
										}
									]}
									onClick={submitHandler}
								>
									<SaveIcon />
								</IconButton>
							</div>
						}
						{
							(enter !== 'signup') &&
							<div className="flex pt-4">
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="login"
									// disabled={processing}
									sx={[
										{
											color: '#e0e0e0',
											padding: '0px'
										},
										{
											'&:hover': {
												color: '#9747ff'
											}
										}
									]}
									onClick={loginHandler}
								>
									<LoginIcon />
								</IconButton>
							</div>
						}
					</div>
					<div className="flex w-full justify-start items-center sm:w-[400px] pt-6">
						{
							(enter === 'signup') &&
							<div className="flex flex-col justify-start items-start">
								<Text className='font-light text-[0.8rem] italic mt-2 hover:text-[#9747ff] hover:cursor-pointer' onClick={loginUser}>
									Already registered, log in
								</Text>
							</div>
						}
						{
							(enter !== 'signup') &&
							<div className="flex flex-col justify-start items-start">
								<Text className='font-light text-[0.8rem] italic mt-2 hover:text-[#9747ff] hover:cursor-pointer' onClick={signupUser}>
									Not registered yet, sign up
								</Text>
								<a
									href="mailto:WantTo@BeOdyssey.com"
									title="For password recovery please contact WantTo@BeOdyssey.com"
									className='hover:cursor-pointer'
									target='_blank'
									rel='noreferrer'
								>
									<Text className='font-light text-[0.8rem] italic mt-2 hover:text-[#9747ff] hover:cursor-pointer'>
										Restore password
									</Text>
								</a>
							</div>
						}
					</div>
				</div>
				<div className="flex items-center justify-center ml-[100px] h-full">
					{
						!processing &&
						<img
							className="aspect-square rounded-full w-[450px] h-[450px]"
							src="secret.webp"
							alt="Secret"
						/>
					}
					{
						processing &&
						<AnimatedImg
							className="aspect-square rounded-full w-[450px] h-[450px]"
							src="secret.webp"
							alt="Secret"
						/>
					}
				</div>
			</div>
			<div className="landscape:hidden flex flex-col items-center justify-center pl-2 pr-2">
				<div className="flex items-center justify-center flex-col pl-4 pr-4">
					<div className="flex flex-col w-full justify-center items-center sm:w-[350px]">
						<FormControl
							sx={[
								{ m: 1, width: '90%' },
								{	
									'& label.Mui-focused': {
										color: '#FFFFFF',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: '#FFFFFF'
									}
								}
							]}
							variant="standard"
						>
						<InputLabel
							htmlFor="login-portrait"
							sx={[
								{ fontFamily: 'Fredoka', color: '#FFFFFF' }
							]}
						>
							Login
						</InputLabel>
						<Input
							id="login-portrait"
							required
							value={login}
							onChange={changeLogin}
							onKeyDown={handleKeyDown}
							// disabled={processing}
							sx={[
								{ 
									fontFamily: 'Fredoka',
									color: '#FFFFFF',
									borderBottom: loginError? '1px solid red' : '1px solid #FFFFFF'
								},
								{
									'&:hover': {
										borderBottom: loginError? '1px solid red' : '1px solid #FFFFFF'
									}
								}
							]}
						/>
						</FormControl>
						{
							(enter === 'signup') &&
							<FormControl
								sx={[
									{ m: 1, width: '90%' },
									{	
										'& label.Mui-focused': {
											color: '#FFFFFF',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: '#FFFFFF'
										}
									}
								]}
								variant="standard"
							>
							<InputLabel
								htmlFor="email"
								sx={[
									{ fontFamily: 'Fredoka', color: '#FFFFFF' }
								]}
							>
								Email
							</InputLabel>
							<Input
								id="email"
								required
								value={email}
								onChange={changeEmail}
								onKeyDown={handleKeyDown}
								// disabled={processing}
								sx={[
									{ 
										fontFamily: 'Fredoka',
										color: '#FFFFFF',
										borderBottom: emailError? '1px solid red' : '1px solid #FFFFFF'
									},
									{
										'&:hover': {
											borderBottom: emailError? '1px solid red' : '1px solid #FFFFFF'
										}
									}
								]}
							/>
							</FormControl>
						}
						<FormControl
							sx={[
								{ m: 1, width: '90%' },
								{	
									'& label.Mui-focused': {
										color: '#FFFFFF',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: '#FFFFFF'
									}
								}
							]}
							variant="standard">
						<InputLabel
							htmlFor="secret-code-portrait"
							sx={[
								{ fontFamily: 'Fredoka', color: '#FFFFFF' }
							]}
						>
							Password
						</InputLabel>
						<Input
							id="secret-code-portrait"
							type={showSecretCode ? 'text' : 'password'}
							required
							value={secretCode}
							onChange={changeSecretCode}
							onKeyDown={handleKeyDown}
							// disabled={processing}
							sx={[
								{ 
									fontFamily: 'Fredoka',
									color: '#FFFFFF',
									borderBottom: secretCodeError? '1px solid red' : '1px solid #FFFFFF'
								},
								{
									'&:hover': {
										borderBottom: secretCodeError? '1px solid red' : '1px solid #FFFFFF'
									}
								}
							]}
							endAdornment={
							<InputAdornment position="end">
								<IconButton
								aria-label="toggle secret code visibility"
								onClick={handleClickShowSecretCode}
								sx={[
									{ color: '#FFFFFF' },
									{
										'&:hover': {
											color: '#9747ff'
										}
									}
								]}
								edge="end"
								>
								{showSecretCode ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
							}
						/>
						</FormControl>
						{
							(enter === 'signup') &&
							<FormControl
								sx={[
									{ m: 1, width: '90%' },
									{	
										'& label.Mui-focused': {
											color: '#FFFFFF',
										},
										'& .MuiInput-underline:after': {
											borderBottomColor: '#FFFFFF'
										}
									}
								]}
								variant="standard">
							<InputLabel
								htmlFor="secret-code2"
								sx={[
									{ fontFamily: 'Fredoka', color: '#FFFFFF' }
								]}
							>
								Repeat password
							</InputLabel>
							<Input
								id="secret-code2"
								type={showSecretCode2 ? 'text' : 'password'}
								required
								value={secretCode2}
								onChange={changeSecretCode2}
								onKeyDown={handleKeyDown}
								// disabled={processing}
								sx={[
									{ 
										fontFamily: 'Fredoka',
										color: '#FFFFFF',
										borderBottom: secretCode2Error? '1px solid red' : '1px solid #FFFFFF'
									},
									{
										'&:hover': {
											borderBottom: secretCode2Error? '1px solid red' : '1px solid #FFFFFF'
										}
									}
								]}
								endAdornment={
								<InputAdornment position="end">
									<IconButton
									aria-label="toggle secret code visibility"
									onClick={handleClickShowSecretCode2}
									sx={[
										{ color: '#FFFFFF' },
										{
											'&:hover': {
												color: '#9747ff'
											}
										}
									]}
									edge="end"
									>
									{showSecretCode2 ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
								}
							/>
							</FormControl>
						}
					</div>
					<div className="flex w-full justify-end items-center mr-7">
						{
							(enter === 'signup') &&
							<div className="flex pt-4">
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="save"
									// disabled={processing}
									sx={[
										{
											color: '#e0e0e0',
											padding: '0px'
										},
										{
											'&:hover': {
												color: '#9747ff'
											}
										}
									]}
									onClick={submitHandler}
								>
									<SaveIcon />
								</IconButton>
							</div>
						}
						{
							(enter !== 'signup') &&
							<div className="flex pt-4">
								<IconButton
									size="large"
									edge="start"
									color="inherit"
									aria-label="login"
									// disabled={processing}
									sx={[
										{
											color: '#e0e0e0',
											padding: '0px'
										},
										{
											'&:hover': {
												color: '#9747ff'
											}
										}
									]}
									onClick={loginHandler}
								>
									<LoginIcon />
								</IconButton>
							</div>
						}
					</div>
					<div className="flex w-full justify-start items-center sm:w-[300px] pt-0">
						{
							(enter === 'signup') &&
							<div className="flex flex-col justify-start items-start overflow:auto">
								<Text className='font-light text-[0.6rem] italic mb-2 hover:text-[#9747ff] hover:cursor-pointer' onClick={loginUser}>
									Already registered, log in
								</Text>
							</div>
						}
						{
							(enter !== 'signup') &&
							<div className="flex flex-col justify-start items-start overflow:auto">
								<Text className='font-light text-[0.6rem] italic hover:text-[#9747ff] hover:cursor-pointer' onClick={signupUser}>
									Not registered yet, sign up
								</Text>
								<a
									href="mailto:WantTo@BeOdyssey.com"
									title="For password recovery please contact WantTo@BeOdyssey.com"
									className='hover:cursor-pointer'
									target='_blank'
									rel='noreferrer'
								>
									<Text className='font-light text-[0.6rem] italic mt-2 mb-2 hover:text-[#9747ff] hover:cursor-pointer'>
										Restore password
									</Text>
								</a>
							</div>
						}
					</div>
				</div>
				<div className="flex items-center justify-center">
					{
						!processing &&
						<img
							className="aspect-square rounded-full w-[150px] h-[150px] sm:w-[150px] sm:h-[150px] md:w-[200px] md:h-[200px] lg:w-[200px] lg:h-[200px] xl:w-[200px] xl:h-[200px] 2xl:w-[200px] 2xl:h-[200px]"
							src="secret.webp"
							alt="Secret"
						/>
					}
					{
						processing &&
						<AnimatedImg
							className="aspect-square rounded-full w-[150px] h-[150px] sm:w-[150px] sm:h-[150px] md:w-[200px] md:h-[200px] lg:w-[200px] lg:h-[200px] xl:w-[200px] xl:h-[200px] 2xl:w-[200px] 2xl:h-[200px]"
							src="secret.webp"
							alt="Secret"
						/>
					}
				</div>
			</div>
		</div>
	)
}

const Text = styled.div`
	font-family: "Fredoka";
	color: #FFFFFF;
	text-align: justify;
`;

const AnimatedImg = styled.img`
	animation: 1.5s linear 0s normal none infinite running rot;
	-webkit-animation: 1.5s linear 0s normal none infinite running rot;

	@keyframes rot {
		0% {
		  transform: rotate(360deg);
		}
		100% {
		  transform: rotate(0deg);
		}
	  }
	  @-webkit-keyframes rot {
		0% {
		  transform: rotate(360deg);
		}
		100% {
		  transform: rotate(0deg);
		}
	  }
`;
