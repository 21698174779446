import { HeaderProps } from './Header.props'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { useAppSelector } from '../../store/hooks'

export const Header = ({ className, ...props }: HeaderProps): JSX.Element => {
	const login = useAppSelector(state => state.login)
	const secret = useAppSelector(state => state.secret.login)

	return (
		<div className='absolute top-[30px] flex flex-col items-end justify-start w-full z-40'>
			{
				!(login.logged && secret) &&
				<div className="flex items-center justify-end mr-[30px]">	
					<a
						href="mailto:WantTo@BeOdyssey.com"
						title="For questions, contact WantTo@BeOdyssey.com"
						className='hover:cursor-pointer'
						target='_blank'
						rel='noreferrer'
					>
						<HelpOutlineIcon
							sx={{
								color: '#FFFFFF'
							}}
						/>
					</a>	
				</div>
			}
		</div>
	)				
}
